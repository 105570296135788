// React
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// Redux
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { setLanguage } from './actions/user';
// Components
import { Home } from './components/home/Home';

function App() {
	const dispatch: Dispatch<any> = useDispatch();

	useEffect(() => {
		if (document.location.search.slice(1) === 'qrcode=true') {
			window.history.pushState({}, document.title, window.location.pathname);
		}
	}, []);

	useEffect(() => {
		console.log('Middelburg : Launch App');

		let languageStart = 'en';
		if (window.navigator.language === 'fr-FR') {
			languageStart = 'du';
		}
		dispatch(setLanguage(languageStart));
	}, [dispatch]);

	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/:place' element={<Home />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
