// React
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Three
import * as THREE from 'three';
// Fe
import { FiktivEngine, fiktiv_input_type, GameStateBase } from '../../fiktivengine_modules/fiktivengine-core';
// Level
import { LMSphere } from './level/LMSphere';

export function Sphere360(props: any) {
	const language: string = useSelector((state: any) => state.user.language);
	const { place } = useParams();

	let [fe, setFe] = useState<FiktivEngine>();

	useEffect(() => {
		if (fe) {
			props.setMaxSize(fe.renderer.capabilities.maxTextureSize);
		}
	}, [fe])

	useEffect(() => {
		if (language) {
			const container = document.getElementById('viewport');

			const gs = new GameStateBase({
				map: new LMSphere({
					lang: language,
					place: place
				}),
			});

			const args: fiktiv_input_type = {
				graphic_enabled: true,
				input_enabled: true,
				physic_enabled: true,
				container: container,
				multi: false,
				gameState: gs,
			};

			setFe(new FiktivEngine(args));
		}
	}, []);

	return (
		<React.Fragment>
			{/* Viewport */}
			<div
				id='viewport'
				style={{
					top: 0,
					left: 0,
					position: 'absolute',
					width: '100%',
					height: '100%',
					margin: 'auto',
					pointerEvents: 'all',
				}}
			/>
		</React.Fragment>
	);
}
