// React
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
// Redux
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../actions/user';
// Styles
import './language.css';
// Utils
import { trads as allTrads } from '../../datas/trads';
// Assets
import kuiperspoortBg from '../../style/assets/kuiperspoort/background.jpg';
import abdijBg from '../../style/assets/abdij/background.jpg';
import grotemarktBg from '../../style/assets/grotemarkt/background.jpg';
import duFlag from '../../style/assets/language/flags/du.png';
import enFlag from '../../style/assets/language/flags/en.png';
import geFlag from '../../style/assets/language/flags/ge.png';
import { useEffect, useState } from 'react';

export const Language = (props: any) => {
	const language: string = useSelector((state: any) => state.user.language);
	const navigate = useNavigate();
	const dispatch: Dispatch<any> = useDispatch();

	const [background, setBackground] = useState(kuiperspoortBg);
	const [menuLang, displayMenuLang] = useState(false);

	const goNext = () => {
		if (props.next) {
			props.next();
		}

		let languageElem = document.getElementById('language');
		if (languageElem) {
			languageElem.style.opacity = '0';
		}
	};

	const changeLang = (lang: string) => {
		console.log('↳ Change lang: ', lang);
		dispatch(setLanguage(lang));
	};

	useEffect(() => {
		switch (props.place) {
			case 'kuiperspoort':
				setBackground(kuiperspoortBg);
				break;
			case 'abdij':
				setBackground(abdijBg);
				break;
			case 'grotemarkt':
				setBackground(grotemarktBg);
				break;
		}
	}, []);

	// @ts-ignore
	let trads = allTrads[language];

	const options = [
		{ value: 'du', label: 'Dutch', image: duFlag },
		{ value: 'en', label: 'English', image: enFlag },
		{ value: 'ge', label: 'German', image: geFlag },
	];

	return (
		<div
			id='language'
			className='language'
			style={{
				backgroundImage: `url(${background})`,
			}}
		>
			<div className='language-container'>
				<div className='language-part language-title '>
					<div className='language-titleLine'>{trads.languagePage.header1}</div>
					<div className='language-titleLine'>{trads.languagePage.header2}</div>
				</div>
				<div className='language-part language-enterButton '>
					<div className='language-enter clickable ' onClick={(e) => goNext()}>
						ENTER
					</div>
				</div>
				<div className='language-part language-langSelectionContainer '>
					<Select
						id={'language-langSelection'}
						className={'language-langSelection'}
						classNamePrefix={'language-langSelection'}
						defaultValue={options[0]}
						value={options.filter((e) => e.value === language)[0]}
						options={options}
						isSearchable={false}
						formatOptionLabel={(country) => (
							<div className={'language-langOption'}>
								<div
									className='language-langFlag'
									style={{
										backgroundImage: 'url(' + country.image + ')',
										backgroundSize: 'cover',
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center',
									}}
								></div>
								<div className={'language-langLang'}>{country.label}</div>
							</div>
						)}
						onChange={(e) => {
							if (e) {
								changeLang(e.value);
							}
						}}
						menuPlacement={'auto'}
					/>
				</div>
			</div>
			{/* <div
				style={{
					height: '25%',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					fontSize: '1.1em',
					marginBottom: '1em'
				}}
			>
				<div style={{
					textAlign: 'center'
				}}>Select your language</div>
				<div style={{
					textAlign: 'center',
					marginBottom: '0.5em',
				}}>to start your Time Travel</div>
				<div style={{
					fontSize: '1.4em',
					textAlign: 'center'
				}}>"The hidden history of Middelburg"</div>
			</div>
			{Object.entries(trads).map((lang) => {
				let key = lang[0];
				let value = lang[1];

				let flag = duFlag;
				switch (key) {
					case 'du':
						flag = duFlag;
						break;
					case 'en':
						flag = enFlag;
						break;
					case 'ge':
						flag = geFlag;
						break;
				}

				return (
					// <Button onClick={(e) => goNext(key)} key={key} style={{ width: '50%', aspectRatio: 1, backgroundColor: 'red',/*className='w-50 m-2 btn btn-lg btn-primary btnStart'*/
			/* }}>
					// 	{value.languages.buttonDescription}
					// </Button>
					<div
						key={key}
						className='buttonLang '
						style={{
							height: '20%',
							cursor: 'pointer',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center'
						}}
						onClick={(e) => goNext(key)} 
					>
						<div className='buttonLangTitle' style={{
							fontSize: '1.2em',
							marginBottom: '0.4em'
						}}>{value.languages.buttonDescription}</div>
						<div
							style={{
								height: '90%',
								aspectRatio: '1/1',
								backgroundImage: `url(${flag})`,
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'contain',
							}}
						></div>
					</div>
				);
			})} */}
		</div>
	);
};
