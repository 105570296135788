// React
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// Trads
import { trads as allTrads } from '../../datas/trads';
// CSS
import './landingPage.css';
// GIFs
import gifKuiperspoort from '../../style/assets/kuiperspoort/playGif.gif';
import gifAbdij from '../../style/assets/abdij/playGif.gif';
import gifGrotemarkt from '../../style/assets/grotemarkt/playGif.gif';
// Images
import bgKuiperspoort from '../../style/assets/kuiperspoort/background.jpg';
import bgAbdij from '../../style/assets/abdij/background.jpg';
import bgGrotemarkt from '../../style/assets/grotemarkt/background.jpg';

export const LandingPage = (props: any) => {
	const language: string = useSelector((state: any) => state.user.language);

	useEffect(() => {
		let homeElem = document.getElementsByClassName('home');
		if (homeElem) {
			for (let elem of homeElem) {
				(elem as HTMLElement).style.pointerEvents = 'all';
			}
		}

		const scrollManagment = (e?: Event) => {
			let elem = document.getElementById('landingPage-container');
			let elemOpacity = document.getElementById('landingPage-textContainer');
			if (elem && elemOpacity) {
				let { scrollHeight, scrollTop, clientHeight } = elem;

				let max = scrollHeight - clientHeight;
				let percent = ((scrollHeight - scrollTop - clientHeight) * 100) / max;

				if (percent < 0) {
					percent = 0;
				}

				elemOpacity.style.setProperty('--maskHeight', 100 - percent + '%');

				let elemArrow = document.getElementById('landingPage-textScroll');
				if (elemArrow) {
					if (scrollHeight - scrollTop - 15 > clientHeight) {
						elemArrow.style.opacity = '1';
					} else {
						elemArrow.style.opacity = '0';
					}
				}
			}
		};

		scrollManagment();

		document.getElementById('landingPage-container')?.addEventListener('scroll', (e: Event) => scrollManagment(e));
	}, []);

	const goNext = () => {
		console.log('↳ Start playing');

		if (props.next) {
			props.next();
		}

		let languageElem = document.getElementById('landingPage');
		if (languageElem) {
			languageElem.style.opacity = '0';

			// let loader = document.getElementById('loader');

			// if (loader) {
			// 	loader.style.opacity = '1';
			// }

			let homeElem = document.getElementsByClassName('home');
			if (homeElem) {
				for (let elem of homeElem) {
					(elem as HTMLElement).style.pointerEvents = 'none';
				}
			}
		}
	};

	// @ts-ignore
	let trads = allTrads[language];

	let bg = bgKuiperspoort;
	let gif = gifKuiperspoort;

	console.log('place=', props.place);

	switch (props.place) {
		case 'kuiperspoort':
			bg = bgKuiperspoort;
			gif = gifKuiperspoort;
			trads = trads.landingPageKuiperspoort;
			break;
		case 'abdij':
			bg = bgAbdij;
			gif = gifAbdij;
			trads = trads.landingPageAbdij;
			break;
		case 'grotemarkt':
			bg = bgGrotemarkt;
			gif = gifGrotemarkt;
			trads = trads.landingPageGrotemarkt;
			break;
		default:
			bg = bgKuiperspoort;
			gif = gifKuiperspoort;
			trads = trads.landingPageKuiperspoort;
	}

	return (
		<div
			id='landingPage'
			className='landingPage'
			style={{
				backgroundImage: `url(${bg})`,
			}}
		>
			<div id='landingPage-container' className='landingPage-container'>
				<div id='landingPage-header' className='landingPage-header'>
					<div>{trads.header1}</div>
					<div>{trads.header2}</div>
				</div>
				<div id='landingPage-play' className='landingPage-play'>
					<div id='landingPage-playButton' className='landingPage-playButton btnStart ' onClick={(e) => goNext()}>
						<div
							id='landingPage-playButtonGif'
							className='landingPage-playButtonGif'
							style={{
								backgroundImage: `url(${gif})`,
							}}
						>
							<div id='landingPage-playButtonIcon' className='landingPage-playButtonIcon'></div>
						</div>
					</div>
				</div>
				<div id='landingPage-textContainer' className='landingPage-textContainer fade '>
					<div id='landingPage-text' className='landingPage-text'>
						<div className='landingPage-textLine'>{trads.subtitle}</div>
						{trads.content.map((line: string, index: number) => {
							return (
								<div key={'landingPage-textLine-' + index} className={'landingPage-textLine '+(line === " " ? 'landingPage-textLineBlank' : '')}>
									{line}
								</div>
							);
						})}
					</div>
				</div>
				<div id='landingPage-textScroll' className='landingPage-textScroll'></div>
			</div>
		</div>
	);
};
