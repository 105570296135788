import { OrthographicCamera, PerspectiveCamera, Vector2 } from "three";
import { CameraComponent, FiktivEngine } from "..";

export type camera_operator_type = {
  fe: FiktivEngine;
  defaultCameraComponent: CameraComponent;
  theta?: number;
  phi?: number;
  radius?: number;
  sensitivity?: Vector2;
};

export type camera_input_type = {
  camera: PerspectiveCamera | OrthographicCamera;
  renderer: THREE.WebGLRenderer;
  theta?: number;
  phi?: number;
  radius?: number;
  fnUpdate?: Function;
  sensitivity?: Vector2;
};

export class CameraOperator {
  fe: FiktivEngine;

  sensitivity: Vector2;
  theta: number;
  phi: number;
  radius: number;

  currentCameraPawn: CameraComponent;

  get camera(): PerspectiveCamera | OrthographicCamera {
    return this.currentCameraPawn.camera;
  }

  constructor({
    fe,
    defaultCameraComponent,
    sensitivity = new Vector2(0.3, 0.3),
    theta = 270,
    phi = 20,
    radius = 10,
  }: camera_operator_type) {
    this.fe = fe;
    this.sensitivity = sensitivity;
    this.theta = theta;
    this.phi = phi;
    this.radius = radius;
    this.currentCameraPawn = defaultCameraComponent;
  }

  setCameraComponent(cameraComponent: CameraComponent) {
    this.currentCameraPawn = cameraComponent;
  }
}
