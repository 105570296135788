import Ammo from "ammojs-typed";

import { log } from '../logMode';

export class BulletWorld {
  private _DISABLE_DEACTIVATION: number;
  private _TRANSFORM_AUX: Ammo.btTransform;
  private _collisionConfiguration: | Ammo.btDefaultCollisionConfiguration ;
  private _dispatcher: Ammo.btCollisionDispatcher;
  private _broadphase: Ammo.btDbvtBroadphase;
  private _solver: Ammo.btSequentialImpulseConstraintSolver;
  private _physicsWorld: Ammo.btDiscreteDynamicsWorld;
  private _ammo: typeof Ammo;

  get ammo(): typeof Ammo {
    if (this._ammo === undefined)
      console.error("Puffyland | FE - _ammo undef !");
    return this._ammo!;
  }

  get DISABLE_DEACTIVATION(): number {
    return this._DISABLE_DEACTIVATION;
  }

  get TRANSFORM_AUX(): Ammo.btTransform {
    if (this._TRANSFORM_AUX === undefined)
      console.error("Puffyland | FE - _TRANSFORM_AUX undef !");
    return this._TRANSFORM_AUX!;
  }

  get collisionConfiguration(): Ammo.btDefaultCollisionConfiguration {
    if (this._collisionConfiguration === undefined)
      console.error("Puffyland | FE - _collisionConfiguration undef !");
    return this._collisionConfiguration!;
  }

  get dispatcher(): Ammo.btCollisionDispatcher {
    if (this._dispatcher === undefined)
      console.error("Puffyland | FE - _dispatcher undef !");
    return this._dispatcher!;
  }

  get broadphase(): Ammo.btDbvtBroadphase {
    if (this._broadphase === undefined)
      console.error("Puffyland | FE - _broadphase undef !");
    return this._broadphase!;
  }

  get solver(): Ammo.btSequentialImpulseConstraintSolver {
    if (this._solver === undefined)
      console.error("Puffyland | FE - _solver undef !");
    return this._solver!;
  }

  get physicsWorld(): Ammo.btDiscreteDynamicsWorld {
    if (this._physicsWorld === undefined)
      console.error("Puffyland | FE - PhysicsWorld undef !");
    return this._physicsWorld!;
  }

  constructor(ammo: typeof Ammo) {
    this._DISABLE_DEACTIVATION = 4;

    this._ammo = ammo;
    this._TRANSFORM_AUX = new ammo.btTransform();
    this._collisionConfiguration = new ammo.btDefaultCollisionConfiguration();
    this._dispatcher = new ammo.btCollisionDispatcher(
      this.collisionConfiguration
    );
    this._broadphase = new ammo.btDbvtBroadphase();
    this._solver = new ammo.btSequentialImpulseConstraintSolver();
    this._physicsWorld = new ammo.btDiscreteDynamicsWorld(
      this.dispatcher,
      this.broadphase,
      this.solver,
      this.collisionConfiguration
    );
    this.physicsWorld.setGravity(new ammo.btVector3(0, 0, -9.82));
    log("Puffyland | FE -", this.physicsWorld);
    log("Puffyland | FE - physicsWorld initialized !");
    // })
    // .catch(() => {
    //   console.error("No Ammo loaded");
    //   this._TRANSFORM_AUX = undefined;
    //   this._collisionConfiguration = undefined;
    //   this._dispatcher = undefined;
    //   this._broadphase = undefined;
    //   this._solver = undefined;
    //   this._physicsWorld = undefined;
    // });
    log("Puffyland | FE - End BulletWorld");
  }
}
