import React from 'react';
// Icons
import back from '../../style/assets/back.png';
import copyright from '../../style/assets/copyright.png';

export const Menu = (props: any) => {
	const goHome = () => {
		if (props.landingPage) {
			console.log('HOME');
			document.location.reload();
		} else {
			props.setLandingPage(true);
			let videoElement = document.getElementById('video');
			if (videoElement) {
				let audioElement = document.getElementById('audio');

				(videoElement as HTMLVideoElement).pause();
				if (audioElement) {
					(audioElement as HTMLAudioElement).pause();
				}

				let viewport = document.getElementById('viewport');

				if (viewport) {
					viewport.style.opacity = '0';
					viewport.style.width = '0';
					viewport.style.height = '0';
				}
			}
		}
	};

	return (
		<React.Fragment>
			<div
				id='button1'
				className='clickable'
				onClick={() => {
					goHome();
				}}
				style={{
					position: 'absolute',
					top: '20px',
					left: '20px',
					width: '50px',
					height: '50px',
					zIndex: 12000,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',

					borderRadius: '50%',
					backgroundColor: 'rgba(0, 0, 0, 0.2)',
					backgroundImage: `url(${back})`,
					backgroundSize: '20px 20px',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				}}
			></div>

			{props.landingPage && (
				<div
					className='clickable'
					style={{
						position: 'absolute',
						top: '20px',
						right: '20px',
						width: '50px',
						height: '50px',
						zIndex: 14000,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',

						borderRadius: '50%',
						backgroundColor: 'rgba(0, 0, 0, 0.2)',
						backgroundImage: `url(${copyright})`,
						backgroundSize: '30px 30px',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
					}}
					onClick={() => {
						props.displayCredits(true);
					}}
				></div>
			)}
		</React.Fragment>
	);
};
