import { Actor } from "./Actor";
import { KeyBinding } from "./KeyBinding";

//Not sure action would be string… FIXME
export type Action = string;

export interface IInputReceiver extends Actor {
  actions: Map<Action, KeyBinding>;

  handleMouseButton(event: any, code: string, pressed: boolean): void;
  handleMouseMove(event: any, deltaX: number, deltaY: number): void;
  handleMouseWheel(event: any, value: number): void;
  handleTouch(event: any, code: string, pressed: boolean): void;
  handleTouchMove(event: any, deltaX: number, deltaY: number): void;
  handleKeyboardEvent(event: any, code: string, pressed: boolean): void;
}

export class Controller extends Actor implements IInputReceiver {
  actions: Map<Action, KeyBinding>;
  constructor(actions?: Map<Action, KeyBinding>) {
    super();
    this.actions = actions || new Map<Action, KeyBinding>();
  }

  handleMouseButton(e: any, code: string, pressed: boolean) {
    console.warn("FE - MouseButton not binded !");
  }
  handleMouseMove(event: any, deltaX: number, deltaY: number) {
    console.warn("FE - MouseMove not binded !");
  }
  handleMouseWheel(event: any, value: number) {}
  handleTouch(event: any, code: string, pressed: boolean) {
    console.warn("FE - Touch not binded !");
  }
  handleTouchMove(event: any, deltaX: number, deltaY: number) {
    console.warn("FE - TouchMove not binded !");
  }
  handleKeyboardEvent(event: any, code: string, pressed: boolean) {
    console.warn("FE - handleKeyboardEvent not binded !");
  }
}
