// React
import React from 'react';
import { useSelector } from 'react-redux';
// Styles
import './credits.css';
// Trads
import { trads as allTrads } from '../../datas/trads';
// Icons
import close from '../../style/assets/close.png';
import logo1 from '../../style/assets/logos/cupido.png';
import logo2 from '../../style/assets/logos/middelburg.png';
import logo3 from '../../style/assets/logos/wad.png';
import logo4 from '../../style/assets/logos/zeeuws.png';


export const Credits = (props: any) => {
	const language: string = useSelector((state: any) => state.user.language);
	
	const goBack = () => {
		props.displayCredits(false);
	};

	// @ts-ignore
	let trads = allTrads[language];

	return (
		<React.Fragment>
			<div
				className='clickable'
				style={{
					position: 'absolute',
					top: '20px',
					right: '20px',
					width: '50px',
					height: '50px',
					zIndex: 20000,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',

					borderRadius: '50%',
					backgroundColor: 'rgba(0, 0, 0, 0.2)',
					backgroundImage: `url(${close})`,
					backgroundSize: '25px 25px',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				}}
				onClick={() => {
					goBack();
				}}
			></div>
			<div
				className='credits'
				id='credits'
				style={{
					zIndex: 15000,
					position: 'absolute',
					top: 0,
				}}
			>
				<h1 style={{
					marginBottom: '0%'
				}}>Credits</h1>
				<p
					style={{
						margin: '10%',
						width: '80%',
					}}
				>
					{trads.credits.content}
				</p>
				<div className='credits-logo'>
					<div className='credits-logoImg' style={{
						backgroundImage: `url(${logo1})`
					}}></div>
					<div className='credits-logoImg' style={{
						backgroundImage: `url(${logo2})`
					}}></div>
					<div className='credits-logoImg' style={{
						backgroundImage: `url(${logo3})`
					}}></div>
					<div className='credits-logoImg' style={{
						backgroundImage: `url(${logo4})`
					}}></div>
				</div>
			</div>
		</React.Fragment>
	);
};
