import { FiktivEngine } from "../index";
import { FiktivObject3D } from "./Object";
import { Actor } from "./Actor";
import { Controller, Action } from "./Controller";
import { KeyBinding } from "./KeyBinding";
import { Vector3, Quaternion } from "three";

export type Pawn_cons_type<T extends FiktivObject3D> = {
  feObject?: T;
  actions?: Map<Action, KeyBinding>;
  controller?: Controller;
};

export class Pawn<T extends FiktivObject3D> extends Actor {
  private _controller: Controller; //Controller must be set
  private _feObject: T | undefined;

  set position(position: Vector3) {
    this.setPosition(position);
  }

  get position(): Vector3 {
    if (this.feObject === undefined)
      console.error("position not defined ! No Object linked to this pawn.");
    return this.feObject!.mesh.position;
  }

  get quaternion(): Quaternion {
    if (this.feObject === undefined)
      console.error("position not defined ! No Object linked to this pawn.");
    return this.feObject!.mesh.quaternion;
  }

  set physic(physic: boolean) {
    this.feObject!.physic = physic;
  }

  get physic(): boolean {
    if (this.feObject === undefined)
      console.error("physic not defined ! No Object linked to this pawn.");
    return this.feObject!.physic;
  }

  set controller(controller: Controller) {
    this._controller = controller;
  }

  get controller(): Controller {
    return this._controller;
  }

  set feObject(feObject: T | undefined) {
    this._feObject = feObject;
  }

  get feObject(): T | undefined {
    return this._feObject;
  }

  constructor({
    feObject = undefined,
    actions = new Map(),
    controller = undefined,
  }: Pawn_cons_type<T>) {
    super();
    this._feObject = feObject;

    if (feObject) {
      this.move = feObject.move;
      this.update = feObject.update;
      this.setPosition = feObject.setPosition;

      // Is also one child
      this.addChild(feObject);
    }
    if (controller) this._controller = controller;
    else this._controller = new Controller(actions);
  }

  public bindFe(fe: FiktivEngine) {
    this.feObject!.bindFe(fe);
    if (this.feUpdatable) fe.syncList.push(this);
  }
}
