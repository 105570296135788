const logMode = false;

// Logs colors
export const sockRcv = ["%c NVC | <= ", "background:lightblue"]; // Socket Reception
export const sockSnd = ["%c NVC | => ", "background:DodgerBlue"]; // Socket Sending
export const dataChanRcv = ["%c NVC | <= ", "background:green"]; // Datachannel Reception
export const dataChanSnd = ["%c NVC | => ", "background:lime"]; // Datachannel Sending

export const log = (...args: any) => {
    if (logMode) {
        console.log(...args);
    }
}
