// React
import { createReducer } from "@reduxjs/toolkit";
// Types
import { USER_LANGUAGE, IAction } from "../actions/types";

export interface IUserState {
	language: string;
}

const initialState: IUserState = {
	language: "en",
};

export const reducer = createReducer(initialState, (builder) => {
	builder.addCase(USER_LANGUAGE, (state, action: IAction) => {
		state.language = action.payload;
	});
});
