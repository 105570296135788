import Ammo from "ammojs-typed";
import { BufferGeometry } from "three";
import { Player, GameStateBase } from "../."

export function createTriangleShapeByBufferGeometry(
  geometry: BufferGeometry,
  scalingFactor: number = 1
) {
  var mesh = new Ammo.btTriangleMesh(true, true);
  var vertexPositionArray = geometry.attributes.position.array;
  for (var i = 0; i < geometry.attributes.position.count / 3; i++) {
    mesh.addTriangle(
      new Ammo.btVector3(
        vertexPositionArray[i * 9 + 0] * scalingFactor,
        vertexPositionArray[i * 9 + 1] * scalingFactor,
        vertexPositionArray[i * 9 + 2] * scalingFactor
      ),
      new Ammo.btVector3(
        vertexPositionArray[i * 9 + 3] * scalingFactor,
        vertexPositionArray[i * 9 + 4] * scalingFactor,
        vertexPositionArray[i * 9 + 5] * scalingFactor
      ),
      new Ammo.btVector3(
        vertexPositionArray[i * 9 + 6] * scalingFactor,
        vertexPositionArray[i * 9 + 7] * scalingFactor,
        vertexPositionArray[i * 9 + 8] * scalingFactor
      ),
      false
    );
  }
  var shape = new Ammo.btBvhTriangleMeshShape(mesh, true, true);
  return shape;
}

export type NatPlayer = {
  nick: string;
  id: string;
  position: THREE.Vector3;
}

export type NatGameStateBase = {
  players: Array<any>;
  map: string;
}

export function replacer(_: any, value: any) {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  }
  if (value instanceof Player) {
    return {
      dataType: "Player",
      value: {
        nick: value.nick,
        id: value.id,
        position: value.pawn?.position,
      },
    };
  }
  if (value instanceof GameStateBase) {
    return {
      dataType: "GameStateBase",
      value: {
        players: Array.from(value.players.entries()).map(
          (x) => JSON.stringify(x[1], replacer)
        ),
        map: value.map.name,
      },
    };
  } else {
    return value;
  }
}

export function reviver(_: any, value: any) {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      return new Map(value.value);
    }
  }
  if (value.dataType === "Player") {
    return value.value;
  }
  if (value.dataType === "GameStateBase") {
    return {
      players: value.value.players.map(
        (x:any) => JSON.parse(x,reviver)
      ),
      map: value.value.map,
    }
  }
  return value;
}
