import { FiktivEngine } from ".."
import { Kompote, Node } from "./../../../settings/fiktivengine"

type FEKompoteProps = {
    kompote: Kompote<any>
}

export class FEKompote {
    private _kompote: Kompote<any>

    get kompote() {
        return this._kompote;
    }

    constructor({ kompote }: FEKompoteProps) {
        this._kompote = kompote

        console.log("FEKompote - Initialized")
    }

    public createNode<T>(type: (new (data: any, fe?: FiktivEngine) => T), data: any, fe?: FiktivEngine): T {
        return new type(data, fe)
    }

    public async loadNodeHierarchy(id: string): Promise<Node<any>> {
        const node = await this._kompote.loadBaseNode(id)
        return node[0]
    }
}