import { Peer } from "./user";
import { Vector3, Quaternion } from "three";
// Natverk imports
import { replacer } from "./utils";
import { log, dataChanRcv, dataChanSnd } from "./logMode";

type TokenId = string;
type T = any;

//FIXME should not live outside this module…
const tokensCmd: Map<TokenId, Package<T>> = new Map<TokenId, Package<T>>();
const retryMax = 5;

export type sCmd_cons_type = {
  cmd: Command,
  data?: DataType,
  token: TokenId,
  received: boolean
};

export type message_cons_type = {
  toPeer: Peer,
  cmd: any,
  data?: DataType
};

/* export type DataType = string | Moving | User | undefined; */
export type DataType = any

export type Command = "msg" | "ping" | "pong" | "move" | "join" | "data" | "event" | undefined;

export type Moving = {
  nick: string;
  id: string;
  pos: Vector3;
  quat: Quaternion;
};

export function random_string(l: number): string {
  return [...Array(l)]
    .map((i) => (~~(Math.random() * 36)).toString(36))
    .join("");
}

/* Data types*/
//What is really sent to Peers
export class Cmd {
  cmd: Command;
  data: DataType;

  constructor(cmd: Command, data: DataType) {
    this.cmd = cmd;
    this.data = data;
  }
}

export class SCmd extends Cmd {
  token: TokenId;
  received: boolean;

  constructor({
    cmd,
    data,
    token,
    received = false
  }: sCmd_cons_type) {
    super(cmd, data);
    this.token = token;
    this.received = received;
  }
}

/* Send classes */
class Package<T> {
  public _toPeer: Peer;
  public _token: TokenId | undefined;
  public _toSend: T | undefined;
  public _accuse: boolean;
  public _retry: number;

  constructor(toPeer: Peer) {
    this._toPeer = toPeer;
    this._accuse = false;
    this._retry = 0;
  }

  public send() {
    log(JSON.stringify(this._toSend, replacer));
    if (this._toPeer.getPeerConnect().dataChannel.readyState === "open") {
      this._toPeer.getPeerConnect().dataChannel.send(JSON.stringify(this._toSend, replacer));
    } else {
      console.error("DataChannel not open !");
    }
  }

  public check(): boolean {
    if (this._token && tokensCmd.has(this._token)) {
      if (this._retry > retryMax) {
        console.error(this._token + ":Couldn't send to " + this._toPeer.nick);
        return false;
      } else {
        this._retry++;
        this._toPeer.getPeerConnect().dataChannel.send(JSON.stringify(this._toSend, replacer));
        setTimeout(() => this.check(), 1000);
        return false;
      }
    } else {
      log(...dataChanRcv, this._token + ": Received by " + this._toPeer.nick);
      return false;
    }
  }
}

export class SafeCmd extends Package<SCmd> {
  constructor(toPeer: Peer, cmd: Command, data: any) {
    super(toPeer);

    // Set the token
    let randomToken = random_string(10);
    while (tokensCmd.has(randomToken)) randomToken = random_string(10);
    this._token = randomToken;
    tokensCmd.set(this._token, this);

    // Sending
    this._toSend = new SCmd({ cmd: cmd, data: data, token: this._token, received: false });
    log(...dataChanSnd, "send_safeCmd ", this._toSend);
    this.send();

    // Checkup
    setTimeout(() => this.check(), 1000);
  }
}

export class Message extends Package<Cmd> {
  constructor({
    toPeer,
    cmd,
    data
  }: message_cons_type) {
    super(toPeer);

    // Sending
    this._toSend = new Cmd(cmd, data);
    this.send();
  }
}

/* Functions Send/Delete */
export function answer_sCmd(p: Peer, smsg: SCmd) {
  log(...dataChanSnd, "answer_sCmd", smsg);
  const aswr = new SCmd({ cmd: smsg.cmd, data: smsg.data, token: smsg.token, received: true });
  p.getPeerConnect().dataChannel.send(JSON.stringify(aswr));
}

export function delete_sCmd(token: TokenId) {
  tokensCmd.delete(token);
}

export function send_safeCmd(p: Peer, cmd: Command, data: DataType): SafeCmd {
  log(...dataChanSnd, "send_safeCmd to", p);
  return new SafeCmd(p, cmd, data);
}

export function send_cmd(p: Peer, cmd: Cmd) {
  log(...dataChanSnd, "send_cmd to", p, cmd);
  return new Message({ toPeer: p, cmd: cmd });
}

export function send_data(p: Peer, data: DataType): Message {
  log(...dataChanSnd, "send_data to", p, data);
  return new Message({ toPeer: p, cmd: new Cmd(undefined, data)});
}

export function send_safeMessage(p: Peer, msg: string): SafeCmd {
  log(...dataChanSnd, "send_safeMessage to", p, msg);
  return new SafeCmd(p, "msg", msg);
}

export function send_safeEvent(p: Peer, event: string): SafeCmd {
  log(...dataChanSnd, "send_safeEvent to", p, event);
  return new SafeCmd(p, "event", event);
}

export function send_brut_message(p: Peer, msg: string) {
  log(...dataChanSnd, "send_brut_message to", p, msg);
  p.getPeerConnect().dataChannel.send(msg);
}
