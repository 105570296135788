// User Actions
export const USER_LANGUAGE = 'user_language';

// DispatchType
export type DispatchType = (args: any) => any;
// Iaction
export interface IAction {
	type: string;
	payload?: any;
}
