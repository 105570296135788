// React
import { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { LandingPage } from '../landingPage/LandingPage';
// Components
import { Menu } from './Menu';
import { Loader } from './Loader';
import { Language } from '../language/Language';
import { Sphere360 } from '../sphere360/Sphere360';
import { useSelector } from 'react-redux';
import { Credits } from '../credits/Credits';

export const Home = (props: any) => {
	const language: string = useSelector((state: any) => state.user.language);
	const navigate = useNavigate();
	const { place } = useParams();

	const [videoName, setVideoName] = useState('MIDDELBURG_ABDIJ_3K');
	const [maxSize, setMaxSize] = useState(8192);
	const [isLangSet, setIsLangSet] = useState(false);
	const [landingPage, setLandingPage] = useState(false);
	const [buffered, setBuffered] = useState(0);

	const [pausedSince, setPausedSince] = useState(50);
	const [loader, setLoader] = useState(false);

	const [credits, displayCredits] = useState(false);

	useEffect(() => {}, [buffered]);

	useEffect(() => {
		let inter = setInterval(() => {
			if (pausedSince > 0) {
				if (Date.now() - pausedSince > 1000) {
					setLoader(true);
					return;
				}
			}
			setLoader(false);
		}, 1000);

		return () => clearInterval(inter);
	});

	useEffect(() => {}, [pausedSince]);

	useEffect(() => {
		if (landingPage) {
			setTimeout(() => {
				setIsLangSet(true);
			}, 500);
		}

		if (isLangSet && !landingPage) {
			let videoElement = document.getElementById('video');

			let viewport = document.getElementById('viewport');

			if (viewport) {
				viewport.style.opacity = '1';
				viewport.style.width = '100%';
				viewport.style.height = '100%';
			}

			if (videoElement) {
				videoElement.oncanplaythrough = function () {
					let audioElement = document.getElementById('audio');

					if (isLangSet) {
						(videoElement as HTMLVideoElement).play();
						if (audioElement) {
							(audioElement as HTMLAudioElement).play();
						}
					}
				};
			}
		}
	}, [landingPage]);

	const newName = (lang: string) => {
		let name = '';

		if (place && place !== '') {
			switch (place) {
				case 'abdij':
					name = 'MIDDELBURG_ABDIJ';
					break;
				case 'kuiperspoort':
					name = 'MIDDELBURG_KUIPERSPOORT';
					break;
				case 'grotemarkt':
					name = 'MIDDELBURG_GROTEMARKT';
					break;
				default:
					navigate('/abdij');
			}

			switch (lang) {
				case 'du':
					name += '_NL';
					break;
				case 'en':
					name += '_EN';
					break;
				case 'de':
					name += '_GE';
					break;
				default:
					name += '_GE';
			}

			if (maxSize <= 2880) {
				name += '_2K';
			} /*if (maxSize <= 4096)*/ else {
				name += '_3K';
			} /*else {
				name += '_4K';
			} /*else {
				name += '_5K';
			}*/
			console.log('VideoName =', name);
			setVideoName(name);
		} else {
			navigate('/abdij');
		}
	};

	useEffect(() => {
		console.log('↳ Place: ', place);

		newName(language);
	}, [language]);

	return (
		<div style={{ width: '100%', height: '100%' }}>
			{/* THREE Instance */}
			{isLangSet && <Menu landingPage={landingPage} setLandingPage={setLandingPage} displayCredits={displayCredits} />}
			{isLangSet && <Sphere360 setMaxSize={setMaxSize} />}
			<div className='home'>
				{/* Video hide in background */}
				<video
					id='video'
					/*muted={videoName.search('MIDDELBURG_GROTEMARKT') >= 0}*/
					loop
					playsInline
					preload='metadata'
					width='0'
					height='0'
					src={'./assets/videos/' + videoName + '.mp4'}
					style={{
						position: 'absolute',
						opacity: 0,
						margin: 0,
						padding: 0,
					}}
					onCanPlay={(e) => {
						if (isLangSet) {
							e.currentTarget.play();
						}
					}}
					onWaiting={(e) => {
						setPausedSince(Date.now());
					}}
					onPlaying={(e) => {
						setPausedSince(-1);
					}}
					onProgress={(e) => {
						let video = e.currentTarget;

						if (video) {
							if (video.buffered.length > 0) {
								var bufferedEnd = video.buffered.end(video.buffered.length - 1);
								var duration = video.duration;
								if (duration > 0) {
									let bufferedAmount = document.getElementById('buffered-amount');
									if (bufferedAmount) {
										bufferedAmount.style.width = (bufferedEnd / duration) * 100 + '%';
										setBuffered(Math.trunc((bufferedEnd / duration) * 100));
									}
								}
							}
						}
					}}
					onTimeUpdate={(e) => {
						let video = e.currentTarget;

						if (video) {
							var duration = video.duration;
							if (duration > 0) {
								let progressAmount = document.getElementById('progress-amount');
								if (progressAmount) {
									progressAmount.style.width = (video.currentTime / duration) * 100 + '%';
								}
							}
						}
					}}
				>
					{/* <source src={'./assets/videos/' + videoName + '.mp4'} type='video/mp4'></source> */}
					<source src={'./assets/videos/' + videoName + '.webm'} type='video/webm'></source>
				</video>
				{/* {videoName.search('MIDDELBURG_GROTEMARKT') >= 0 && (
					<audio id='audio' loop preload='metadata' src={'./assets/audios/MIDDELBURG_GROTEMARKT.mp3'}></audio>
				)} */}
				<div
					className='buffered'
					style={{
						height: '8px',
						position: 'absolute',
						background: '#555',
						width: 'calc(100% - 20px)',
						left: '10px',
						bottom: '10px',
						opacity: '0.4',
					}}
				>
					<span
						id='buffered-amount'
						style={{
							display: 'block',
							height: '100%',
							backgroundColor: '#888',
							width: '0',
						}}
					></span>
				</div>
				<div
					className='progress'
					style={{
						height: '8px',
						position: 'absolute',
						width: 'calc(100% - 20px)',
						left: '10px',
						bottom: '10px',
						opacity: '0.3',
					}}
				>
					<span
						id='progress-amount'
						style={{
							display: 'block',
							height: '100%',
							backgroundColor: '#595',
							width: '0',
						}}
					></span>
				</div>

				{/* Choose Language */}
				{!isLangSet && (
					<Language
						place={place}
						next={() => {
							setLandingPage(true);
						}}
					/>
				)}

				{/* Landing Page */}
				{landingPage && (
					<LandingPage
						place={place}
						next={() => {
							setPausedSince(Date.now());

							let videoElement = document.getElementById('video');
							if (videoElement) {
								let audioElement = document.getElementById('audio');

								(videoElement as HTMLVideoElement).play();
								if (audioElement) {
									(audioElement as HTMLAudioElement).play();
								}
							} else {
								alert('video not ok');
							}

							setTimeout(() => {
								setIsLangSet(true);
								setLandingPage(false);
							}, 500);
						}}
					/>
				)}
			</div>
			{loader && isLangSet && !landingPage && <Loader buffered={buffered} />}
			{/* <div
				id='debug'
				style={{
					display: 'none',
					position: 'absolute',
					width: '300px',
					height: '150px',
					backgroundColor: 'red',
					top: '70px',
					left: '10px',
					color: 'black',
				}}
			></div> */}

			{credits && <Credits displayCredits={displayCredits} />}
		</div>
	);
};
