import { Controller } from "./Controller";

export class InputManager {
  public domElement: HTMLCanvasElement;
  public pointerLock: boolean;
  public isLocked: boolean;

  //Pawn ou player ?
  public inputReceiver: Controller | undefined;

  // FIXME ↓ This is ugly ↓ TODO ev type ?
  public boundOnMouseDown: (ev: any) => void;
  public boundOnMouseMove: (ev: any) => void;
  public boundOnMouseUp: (ev: any) => void;
  public boundOnMouseWheelMove: (ev: any) => void;
  public boundOnTouchStart: (ev: any) => void;
  public boundOnTouchMove: (ev: any) => void;
  public boundOnTouchEnd: (ev: any) => void;
  public boundOnTouchCancel: (ev: any) => void;
  public boundOnPointerlockChange: (ev: any) => void;
  public boundOnPointerlockError: (ev: any) => void;
  public boundOnKeyDown: (ev: any) => void;
  public boundOnKeyUp: (ev: any) => void;

  constructor(domElement: HTMLCanvasElement, pointerLock: boolean) {
    this.domElement = domElement;
    this.pointerLock = pointerLock;
    this.isLocked = false;
    this.inputReceiver = undefined;

    // Mouse
    this.boundOnMouseDown = (evt) => this.onMouseDown(evt);
    this.boundOnMouseMove = (evt) => this.onMouseMove(evt);
    this.boundOnMouseUp = (evt) => this.onMouseUp(evt);
    this.boundOnMouseWheelMove = (evt) => this.onMouseWheelMove(evt);

    // Touchscreen
    this.boundOnTouchStart = (evt) => this.onTouchStart(evt);
    this.boundOnTouchMove = (evt) => this.onTouchMove(evt);
    this.boundOnTouchEnd = (evt) => this.onTouchEnd(evt);
    this.boundOnTouchCancel = (evt) => this.onTouchCancel(evt);

    // Pointer lock
    this.boundOnPointerlockChange = () => this.onPointerlockChange();
    this.boundOnPointerlockError = () => this.onPointerlockError();

    // Keys
    this.boundOnKeyDown = (evt) => this.onKeyDown(evt);
    this.boundOnKeyUp = (evt) => this.onKeyUp(evt);

    // Init event listeners
    // Mouse
    this.domElement.addEventListener("mousedown", this.boundOnMouseDown, false);
    document.addEventListener("wheel", this.boundOnMouseWheelMove, false);
    document.addEventListener(
      "pointerlockchange",
      this.boundOnPointerlockChange,
      false
    );
    document.addEventListener(
      "pointerlockerror",
      this.boundOnPointerlockError,
      false
    );

    // Touchscreen
    this.domElement.addEventListener(
      "touchstart",
      this.boundOnTouchStart,
      false
    );

    // Keys
    document.addEventListener("keydown", this.boundOnKeyDown, false);
    document.addEventListener("keyup", this.boundOnKeyUp, false);
  }

  public setInputReceiver(receiver: Controller) {
    this.inputReceiver = receiver;
    //this.inputReceiver.inputReceiverInit();
  }

  public setPointerLock(enabled: boolean) {
    this.pointerLock = enabled;
  }

  public onPointerlockChange() {
    if (document.pointerLockElement === this.domElement) {
      this.domElement.addEventListener(
        "mousemove",
        this.boundOnMouseMove,
        false
      );
      this.domElement.addEventListener("mouseup", this.boundOnMouseUp, false);
      this.isLocked = true;
    } else {
      this.domElement.removeEventListener(
        "mousemove",
        this.boundOnMouseMove,
        false
      );
      this.domElement.removeEventListener(
        "mouseup",
        this.boundOnMouseUp,
        false
      );
      this.isLocked = false;
    }
  }

  public onPointerlockError() {
    console.error(
      "Puffyland | FE - PointerLockControls: Unable to use Pointer Lock API"
    );
  }

  public onMouseDown(event: any) {
    if (this.pointerLock) {
      this.domElement.requestPointerLock();
    } else {
      this.domElement.addEventListener(
        "mousemove",
        this.boundOnMouseMove,
        false
      );
      this.domElement.addEventListener("mouseup", this.boundOnMouseUp, false);
    }

    if (this.inputReceiver !== undefined) {
      this.inputReceiver.handleMouseButton(event, "mouse" + event.button, true);
    }
  }

  onMouseMove(event: any) {
    if (this.inputReceiver !== undefined) {
      this.inputReceiver.handleMouseMove(
        event,
        event.movementX,
        event.movementY
      );
    }
  }

  onMouseUp(event: any) {
    if (!this.pointerLock) {
      this.domElement.removeEventListener(
        "mousemove",
        this.boundOnMouseMove,
        false
      );
      this.domElement.removeEventListener(
        "mouseup",
        this.boundOnMouseUp,
        false
      );
    }

    if (this.inputReceiver !== undefined) {
      this.inputReceiver.handleMouseButton(
        event,
        "mouse" + event.button,
        false
      );
    }
  }

  onTouchStart(event: any) {
    this.domElement.addEventListener("touchmove", this.boundOnTouchMove, false);
    this.domElement.addEventListener("touchend", this.boundOnTouchEnd, false);
    this.domElement.addEventListener(
      "touchcancel",
      this.boundOnTouchCancel,
      false
    );

    if (this.inputReceiver !== undefined) {
      this.inputReceiver.handleTouch(event, "touch", true);
    }
  }

  onTouchMove(event: any) {
    if (this.inputReceiver !== undefined) {
      this.inputReceiver.handleTouchMove(
        event,
        event.movementX,
        event.movementY
      );
    }
  }

  onTouchEnd(event: any) {
    this.domElement.removeEventListener(
      "touchmove",
      this.boundOnTouchMove,
      false
    );
    this.domElement.removeEventListener(
      "touchend",
      this.boundOnTouchEnd,
      false
    );
    this.domElement.removeEventListener(
      "touchcancel",
      this.boundOnTouchCancel,
      false
    );

    if (this.inputReceiver !== undefined) {
      this.inputReceiver.handleTouch(event, "touch", false);
    }
  }

  onTouchCancel(event: any) {
    this.domElement.removeEventListener(
      "touchmove",
      this.boundOnTouchMove,
      false
    );
    this.domElement.removeEventListener(
      "touchend",
      this.boundOnTouchEnd,
      false
    );
    this.domElement.removeEventListener(
      "touchcancel",
      this.boundOnTouchCancel,
      false
    );

    if (this.inputReceiver !== undefined) {
      this.inputReceiver.handleTouch(event, "touch", false);
    }
  }

  onKeyDown(event: any) {
    if (this.inputReceiver !== undefined) {
      this.inputReceiver.handleKeyboardEvent(event, event.code, true);
    }
  }

  onKeyUp(event: any) {
    if (this.inputReceiver !== undefined) {
      this.inputReceiver.handleKeyboardEvent(event, event.code, false);
    }
  }

  onMouseWheelMove(event: any) {
    if (this.inputReceiver !== undefined) {
      this.inputReceiver.handleMouseWheel(event, event.deltaY);
    }
  }
}
