//Map
import { FiktivLevel } from "../../engine/FiktivLevel";
//Objects
import { Actor } from "../../engine/Actor";

import { FiktivEngine } from "../../index";

import * as THREE from "three";

export class Sandbox extends FiktivLevel {
  // What the use of actors again ? FIXME ?
  /*declare actors: Map<string, Actor>;
  declare name: string;

  declare initPos: THREE.Vector3;
  declare initQuat: THREE.Quaternion;
  declare onStart: (args: any) => void;
  declare onStop: (args: any) => void;*/

  public actors: Map<string, Actor>;
  public name: string;

  public initPos: THREE.Vector3;
  public initQuat: THREE.Quaternion;
  public onStart: (args: any) => void;
  public onStop: (args: any) => void;

  ZERO_QUATERNION: THREE.Quaternion = new THREE.Quaternion(0, 0, 0, 1);
  ISO_QUATERNION: THREE.Quaternion = new THREE.Quaternion().setFromAxisAngle(
    new THREE.Vector3(0.96, 0, 0.79),
    Math.PI / 2
  );

  init(_:FiktivEngine) {
    //build level
  }

  constructor(fe?: FiktivEngine) {
    super();
    this.actors = new Map<string, Actor>();
    this.initPos = new THREE.Vector3(0, 0, 3);
    this.initQuat = this.ZERO_QUATERNION;
    this.onStart = () => {};
    this.onStop = () => {};
    this.name = Sandbox.name;
    if(fe)
      this.init(fe)
  }
}
