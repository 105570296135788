import { io, Socket } from "socket.io-client";

export type socketinterface_functions_cons_type = {
	init: Function,
	emit: Function,
	on: Function
}

export type socketinterface_cons_type = {
  url: string,
  type?: string,
  reconnection: boolean,
  functions?: socketinterface_functions_cons_type | undefined;
};

export class SocketInterface {
  public url: string;
  public type: string;
  public reconnection: boolean;
  public functions: socketinterface_functions_cons_type | undefined;

  public id: string;

  private _socket: Socket | undefined;

  public constructor({
    url,
    type = "socketio",
    reconnection,
	functions = undefined
  }: socketinterface_cons_type) {
    this.url = url;
    this.type = type;
    this.reconnection = reconnection;
    this.id = 'tempId';
	this.functions = functions;

    if (this.type === "socketio") {
      this._socket = io(this.url, {
        reconnection: this.reconnection
      });
    } else if (this.type === "other") {
		this._socket = undefined;
		console.log("start in other")
		this.functions?.init();
    } else {
      this._socket = undefined;
    }
  }

  public on(ev: string, fn: (...args: any[]) => void): void {
	if(this.type === "other"){
		this.functions?.on(ev, fn)
	}else{
		this._socket?.on(ev, fn)
	}
  }

  public emit(ev: string, ...arg: any): void {
	if(this.type === "other"){
		console.log("send this : ")
		console.log(arg)

        let temp: string = "";

        if(Array.isArray(arg) && arg.length > 0){
            temp = JSON.stringify(arg[0])

            temp = temp.replace(/\\n/g, "\\n")
                        .replace(/\\/g, "")
                        .replace(/\\'/g, "\\'")
                        .replace(/\\"/g, '\\"')
                        .replace(/\\&/g, "\\&")
                        .replace(/\\r/g, "\\r")
                        .replace(/\\t/g, "\\t")
                        .replace(/\\b/g, "\\b")
                        .replace(/\\f/g, "\\f");

            //temp = temp.substring(1);
            //temp = temp.slice(0, -1);
        }

		this.functions?.emit(ev, temp)
	}else{
		this._socket?.emit(ev, ...arg)
	}
  }
}