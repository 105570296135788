import { OrthographicCamera, PerspectiveCamera, Vector2 } from "three";
import { Pawn } from "./Pawn";
import { FiktivObject3D } from "./Object";
import { update_input_type } from "./FiktivEngine";
import { camera_input_type } from "./CameraOperator";
import { IUpdatable } from "..";
import { log } from '../logMode';

export class CameraComponent implements IUpdatable {
  camera: PerspectiveCamera | OrthographicCamera;
  sensitivity: Vector2;
  _theta: number;
  _phi: number;
  _radius: number;
  private fnUpdate: Function;

  constructor({
    camera,
    sensitivity = new Vector2(0.3, 0.3),
    theta = 270,
    phi = 20,
    radius = 10,
  }: camera_input_type) {
    this.camera = camera;
    this.sensitivity = sensitivity;
    this._theta = theta;
    this._phi = phi;
    this._radius = radius;
    this.fnUpdate = () => {};
  }

  update(args: update_input_type): void {
    if (this.fnUpdate) {
      this.fnUpdate(this.camera);
    } else {
      console.warn("[CameraComponent] - No update function defined");
    }
  }

  //#region Getter / Setters
  get theta(): number {
    return this._theta;
  }

  set theta(t: number) {
    this._theta = t;
  }

  get phi(): number {
    return this._phi;
  }

  set phi(t: number) {
    this._phi = t;
  }

  get radius(): number {
    return this._radius;
  }

  set radius(t: number) {
    this._radius = t;
  }

  setRadius(r: number) {
    this.radius = r;
  }

  setTheta(t: number) {
    this.theta = t;
  }

  setPhi(p: number) {
    this.phi = p;
  }

  setFunctionUpdate(updateFn: Function) {
    this.fnUpdate = updateFn;
  }
  //#endregion

  rotateIso(deltaX: number, deltaY: number) {
    this.theta += deltaX;
    this.theta %= 360;

    log("this.phi =", this.phi);

    this.phi = deltaY;
    this.phi %= 360;
    //this.phi = Math.min(85, Math.max(-85, this.phi));
  }

  rotate(deltaX: number, deltaY: number) {
    this.theta += deltaX * (this.sensitivity.x / 2);
    this.theta %= 360;
    this.phi += deltaY * (this.sensitivity.y / 2);
    this.phi = Math.min(85, Math.max(-85, this.phi));
  }

  zoom(value: number) {
    this.radius += value / 50;
  }

  followPawn(pawn: Pawn<FiktivObject3D>) {
    if (pawn) {
      this.camera.position.x =
        pawn.position.x +
        this.radius *
          Math.sin((this.theta * Math.PI) / 180) *
          Math.cos((this.phi * Math.PI) / 180);
      this.camera.position.y =
        pawn.position.y +
        this.radius *
          Math.cos((this.theta * Math.PI) / 180) *
          Math.cos((this.phi * Math.PI) / 180);
      this.camera.position.z =
        pawn.position.z + this.radius * Math.sin((this.phi * Math.PI) / 180);
      this.camera.lookAt(pawn.position);
    }
  }
}
