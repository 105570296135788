// Modules
import * as THREE from 'three';
import { PerspectiveCamera, Vector3 } from 'three';
// import { degToRad } from "three/src/math/MathUtils";

import { FiktivEngine, Pawn, KeyBinding, Sphere, Sphere_cons_type, update_input_type, CameraComponent } from '../../../fiktivengine_modules/fiktivengine-core';

import { LMSphere } from '../level/LMSphere';

/* import { AVrController } from "../../../fiktivengine_modules/fiktivengine-core/actors/AVrController"; */

export type Sphero_cons_type = {
	fe: FiktivEngine;
	material: THREE.Material;
	pos: THREE.Vector3;
	quat: THREE.Quaternion;
	r?: number;
	mass?: number;
	friction?: number;
	physic?: boolean;
	speed?: number;
	place?: string;
};

export class PSphere extends Pawn<Sphere> {
	private cameraComponent: CameraComponent;
	//public camera: THREE.PerspectiveCamera;
	public velocityX: number = 0;
	public velocityY: number = 0;
	public speed: number = 0.05;
	public frein: number = 0.998;
	public previousTime = new THREE.Clock().getDelta();
	public clock = new THREE.Clock();
	public delta = new THREE.Clock().getDelta();

	public level?: LMSphere;

	public mouseClickStart: number = 0;
	public mousePositionOrigin = new THREE.Vector2();
	public mouseMoving: Boolean = false;
	public cameraOrigin = new THREE.Vector3();

	public place?: string;
	public angle = 0;

	constructor({ fe, material, pos, quat, r = 1, mass = 0, friction = 1, physic = false, speed = 10, place }: Sphere_cons_type) {
		const actions = new Map([
			['up', new KeyBinding('KeyW')],
			['down', new KeyBinding('KeyS')],
			['jump', new KeyBinding('Space')],
			['left', new KeyBinding('KeyA')],
			['right', new KeyBinding('KeyD')],
		]);
		super({
			feObject: new Sphere({
				fe: fe,
				material: material,
				pos: pos,
				quat: quat,
				r: r,
				mass: mass,
				friction: friction,
				physic: physic,
				speed: speed,
			} as Sphere_cons_type),
			actions: actions,
		});

		this.place = place;

		// Camera setup
		const { clientWidth, clientHeight } = fe.renderer.domElement;

		const camera = new THREE.PerspectiveCamera(90, clientWidth / clientHeight, 1, 2000000);

		camera.up.set(0, 0, 1);

		let angle = 0;
		switch (place) {
			case 'abdij':
				angle = 180;
				break;
			case 'kuiperspoort':
				angle = -90;
				break;
			case 'grotemarkt':
				angle = 131;
				break;
			default:
				angle = 0;
				break;
		}
		this.angle = angle;

		camera.rotateOnWorldAxis(new Vector3(1, 0, 0), 0); // 0 * (Math.PI / 180)
		camera.rotateOnWorldAxis(new Vector3(0, 1, 0), angle * Math.PI / 180);
		camera.position.set(0, 0, 0);

		this.cameraComponent = new CameraComponent({
			camera,
			renderer: fe.renderer,
		});

		this.cameraComponent.setFunctionUpdate((camera: PerspectiveCamera) => {
			//this.cameraComponent.followPawn(this);
		});

		fe.cameraOperator.setCameraComponent(this.cameraComponent);

		this.controller.handleMouseButton = (e, code, pressed) => this.handleMouseButton(e, code, pressed);
		this.controller.handleMouseMove = (e, dx, dy) => this.handleMouseMove(e, dx, dy);
		this.controller.handleKeyboardEvent = (e, c, p) => this.handleKeyboardEvent(e, c, p);

		this.controller.handleTouch = (e, code, pressed) => this.handleTouch(e, code, pressed);

		this.bindFe(fe);

		//Handle pointlock
		if (fe.inputManager) {
			fe.inputManager.setPointerLock(false);
		}

		function isSafari() {
			if (navigator.userAgent.match('CriOS')) {
				return false;
			}

			let test = navigator.vendor.match(/[Aa]+pple/g);

			if (test) {
				if (test.length > 0) {
					return true;
				}
			}

			return false;
		}

		let btnStart = document.getElementById('landingPage-playButton');
		let debug = document.getElementById('debug');

		let setObjectQuaternion = (function () {
			var zee = new THREE.Vector3(0, 0, 1);
			var euler = new THREE.Euler();
			var q0 = new THREE.Quaternion();
			var q1 = new THREE.Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5)); // - PI/2 around the x-axis

			return function (quaternion: THREE.Quaternion, alpha: number, beta: number, gamma: number, orient: number) {
				euler.set(beta, alpha, -gamma, 'YXZ'); // 'ZXY' for the device, but 'YXZ' for us
				quaternion.setFromEuler(euler); // orient the device
				quaternion.multiply(q1); // camera looks out the back of the device, not the top
				quaternion.multiply(q0.setFromAxisAngle(zee, -orient)); // adjust for screen orientation
			};
		})();

		let setCameraRotation = function (event: any) {
			if (event.alpha !== null && event.beta !== null && event.gamma !== null) {
				if (debug) {
					debug.innerHTML = '' + event.alpha + '<br />' + event.beta + '<br />' + event.gamma + '';
				}

				var alphaOffset = 0;
				var screenOrientation = 0;

				var alpha = event.alpha ? THREE.MathUtils.degToRad(event.alpha) + alphaOffset : 0; // Z
				var beta = event.beta ? THREE.MathUtils.degToRad(event.beta) : 0; // X'
				var gamma = event.gamma ? THREE.MathUtils.degToRad(event.gamma) : 0; // Y''

				var orient = screenOrientation ? THREE.MathUtils.degToRad(screenOrientation) : 0; // O

				if (orient) {
					beta -= Math.PI / 2;
				}

				setObjectQuaternion(camera.quaternion, alpha, beta, gamma, orient);

				/*camera.setRotationFromEuler(new THREE.Euler(
					event.beta* (Math.PI/180),
					event.gamma* (Math.PI/180),
					event.alpha* (Math.PI/180),
					"XZY"
				));*/

				//camera.setRotationFromEuler(new THREE.Euler(0,0,0));

				camera.rotateOnWorldAxis(new Vector3(0, 1, 0), angle * Math.PI / 180);
			}
		};

		if (isSafari()) {
			if (btnStart) {
				(btnStart as HTMLElement).onclick = function () {
					if (typeof DeviceMotionEvent !== 'undefined' && typeof (DeviceMotionEvent as any).requestPermission === 'function') {
						// (optional) Do something before API request prompt.
						(DeviceMotionEvent as any)
							.requestPermission()
							.then((response: any) => {
								// (optional) Do something after API prompt dismissed.
								if (response == 'granted') {
									window.addEventListener('devicemotion', (e) => {
										// do something for 'e' here.
									});
								}
							})
							.catch(console.error);
					} else {
						alert('DeviceMotionEvent is not defined');
					}

					(DeviceMotionEvent as any).requestPermission().then((response: any) => {
						if (response == 'granted') {
							window.addEventListener('deviceorientation', (event) => {
								setCameraRotation(event);
							});
						}
					});
				};
			}

			/*(DeviceMotionEvent as any).requestPermission().then((response:any) => {
				if (response == 'granted') {
					// Add a listener to get smartphone orientation 
					// in the alpha-beta-gamma axes (units in degrees)
					window.addEventListener('deviceorientation', (event) => {
						alert(event)
					});
				}
			});*/
		} else {
			//console.log("not safari");

			if (btnStart) {
				(btnStart as HTMLElement).onclick = function () {
					if ((DeviceMotionEvent as any).hasOwnProperty('requestPermission')) {
						(DeviceMotionEvent as any).requestPermission().then((response: any) => {
							// alert('DeviceMotionEvent ' + response);
							if (response == 'granted') {
								// Add a listener to get smartphone orientation
								// in the alpha-beta-gamma axes (units in degrees)

								window.addEventListener('devicemotion', (event) => {
									/*if (event && event.acceleration) {
										if (debug) {
											//debug.innerHTML = ""+event.acceleration.x+"<br />"+event.acceleration.y+"<br />"+event.acceleration.z+""
										}
									}*/
								});

								window.addEventListener('deviceorientation', (event) => {
									//console.log(event.absolute)
									//console.log(event.alpha + ' : ' + event.beta + ' : ' + event.gamma);

									setCameraRotation(event);
								});
							}
						});
					} else {
						// alert("DeviceMotionEvent don't have requestPermission");
						window.addEventListener('deviceorientation', (event) => {
							setCameraRotation(event);
						});
					}
				};
			}

			/*window.addEventListener('deviceorientation', function (event) {
				console.log("event")
				console.log(event)
			});*/
		}

		this.update = (args: update_input_type) => {
			if (this.feObject) {
				// this.delta = this.clock.getDelta(); // Normaly deltatime is already set in args, no ?
			}
		};
	}

	handleMouseMove(event: any, deltaX: number, deltaY: number) {
		if (this.feObject) {
			//this.cameraComponent.rotate(deltaX, deltaY);
			/*console.log("okmove")
			console.log(deltaX)*/

			//console.log(this.mousePositionOrigin.x - event.clientX);

			let moveX = event.clientX - this.mousePositionOrigin.x;
			let moveY = event.clientY - this.mousePositionOrigin.y;

			//console.log(moveX)

			this.cameraComponent.camera.rotation.set(this.cameraOrigin.x, this.cameraOrigin.y, this.cameraOrigin.z);
			this.cameraComponent.camera.rotateX(moveY / 400);
			this.cameraComponent.camera.rotateOnWorldAxis(new Vector3(0, 1, 0), moveX / 400);
		} else {
			console.warn('No object for pawn');
		}
	}

	handleMouseWheel(event: any, value: number) {
		//Nothing for now
	}

	checkPoiClick(x: number, y: number) {
		let found = null;
		let distance = 10000;

		let mousePosition = new THREE.Vector2(x / window.innerWidth, y / window.innerHeight);

		if (this.level && (this.level as LMSphere).data) {
			for (let p of (this.level as LMSphere).data.pois) {
				let newDistance = mousePosition.distanceTo(p.positionScreen);

				if (newDistance < distance) {
					distance = newDistance;
					if (distance < 0.1) {
						found = p;
						this.level.currentSelection = p.short;
						console.log(p);
					}
				}
			}
			if (found) {
				if (this.level.setPoi) {
					this.level.setPoi(found.short);
				}
			} else {
				if (this.level.setPoi) {
					this.level.setPoi(null);
					this.level.currentSelection = null;
				}
			}
		}
	}

	handleTouch(event: any, code: string, pressed: boolean) {
		if (event.type === 'touchend') {
			this.checkPoiClick(event.layerX, event.layerY);
		}
	}

	handleMouseButton(event: any, code: string, pressed: boolean) {
		//Nothing for now
		if (event.type === 'mousedown') {
			//console.log("mouseDown")
			this.mouseMoving = true;
			this.mouseClickStart = Date.now();
			this.mousePositionOrigin.set(event.clientX, event.clientY);
			this.cameraOrigin.set(this.cameraComponent.camera.rotation.x, this.cameraComponent.camera.rotation.y, this.cameraComponent.camera.rotation.z);
		} else if (event.type === 'mouseup') {
			let length = Date.now() - this.mouseClickStart;
			this.mouseMoving = false;

			if (length < 150) {
				this.checkPoiClick(event.clientX, event.clientY);
			}
			//console.log("mouseUp")
		}
	}

	handleKeyboardEvent(event: any, code: string, pressed: boolean) {
		//could be more effecient to only get from code…
		//this.actions.get(code) … up down left right is useless
		if (this.controller) {
			for (let binding of this.controller.actions.values()) {
				if (binding.eventCodes === code) {
					if (binding.isPressed !== pressed) {
						binding.isPressed = pressed;
					}
				}
			}
		} else {
			console.warn('[Sphero]: Controller undef ?');
		}
	}
}
