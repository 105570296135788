import { Player } from "./Player";
import { reviver, replacer, NatPlayer, NatGameStateBase } from "./utils";
import {
  NatverkClient,
  ChiefRoom,
  T_onUserLeave,
  T_onUserDisconnect,
  T_onMyRoomReady,
  T_onUserJoin,
  T_onUserPos,
  T_onData,
  Moving,
  Room,
} from "./../../../natverk_modules/natverk-core";

import { FiktivEngine } from "./FiktivEngine";
import { GameStateBase } from "./GameStateBase";

/* import { Pawn } from "../engine/Pawn"; */
/* import { FiktivObject3D } from "./Object"; */
import { FiktivLevel } from "./FiktivLevel";

import { log } from '../logMode';

export type INCommand = "Player" | "FiktivLevel" | "GameState";
// export type INDataType = Player | FiktivLevel | GameStateBase;
export type INDataType = string;

export class INatCmd {
  cmd: INCommand;
  data: INDataType;

  constructor(cmd: INCommand, data: INDataType) {
    this.cmd = cmd;
    this.data = data;
  }
}

export type FENatverk_cons_type = {
  fe: FiktivEngine;
  natverk: NatverkClient;
  netUpdateFrequency?: number;
  bStop?: boolean;
};

export class FENatverk {
  private _natverk: NatverkClient;

  private _NetUpdateFrequency: number; // every in ms

  private _status: boolean; // true → running

  private _nIntervalId: NodeJS.Timeout | undefined;

  private _currentState: GameStateBase;

  public fe: FiktivEngine;

  set currentState(b: GameStateBase) {
    this._currentState = b;
  }

  get currentState(): GameStateBase {
    return this._currentState;
  }

  get natverk(): NatverkClient {
    return this._natverk;
  }

  set NetUpdateFrequency(n: number) {
    this._NetUpdateFrequency = n;
  }

  get NetUpdateFrequency(): number {
    return this._NetUpdateFrequency;
  }

  set status(b: boolean) {
    this._status = b;
  }

  get status(): boolean {
    return this._status;
  }

  constructor({ fe, natverk, netUpdateFrequency = 20 }: FENatverk_cons_type) {
    this.fe = fe;
    this._natverk = natverk;
    this._NetUpdateFrequency = netUpdateFrequency;
    this._status = false;
    this._currentState = fe.gameState;

    natverk.onUserDisconnect.on(this.onUserDisconnect.bind(this));
    natverk.onMyRoomReady.on(this.onMyRoomReady.bind(this));

    // natverk.onUserJoin = (user) => this.onUserJoin(user);
    if (this.natverk.myroom) {
      this.initRoom(this.natverk.myroom);
    }
  }

  public start() {
    if (!this.status) {
      let _this = this;
      this._nIntervalId = setInterval(
        () => _this.update(),
        this.NetUpdateFrequency
      );
      this.status = false;
    } else {
      console.warn(
        "FENatverk - Attempt to START loop communication already running !"
      );
    }
  }

  public stop() {
    if (!this.status) {
      console.warn(
        "FENatverk - Attempt to STOP loop communication already stoped !"
      );
    } else {
      if (this._nIntervalId) {
        clearInterval(this._nIntervalId);
      }
      this.status = true;
    }
  }

  public refresh() {
    if (this._nIntervalId) {
      clearInterval(this._nIntervalId);
    } else {
      console.warn("No intervalId");
    }
    if (!this.status) {
      let _this = this;
      this._nIntervalId = setInterval(
        () => _this.update(),
        this.NetUpdateFrequency
      );
    }
  }

  public sendPlayerPosition(p: Player) {
    this.natverk.send_pos(p.pawn!.position, p.pawn!.quaternion);
  }

  public update() {
    // May send udpates only if state has changed ?
    if (this.natverk.me) {
      //If it's chief instance
      if (this.natverk.myroom && this.natverk.myroom instanceof ChiefRoom) {
        //Broadcast the entire Gamestate to everyone in the room
        this.natverk.myroom.safeBroadcastData(
          new INatCmd("GameState", JSON.stringify(this.fe.gameState, replacer))
        );
      } else {
        //If you're only peer, send your position
        let me = this.fe.gameState.players.get(this.natverk.me.id);
        if (me) {
          this.sendPlayerPosition(me);
        } else {
          console.warn("could not get me");
          return;
        }
      }
    } else {
      console.warn("natverk.me undefined");
      return;
    }
  }

  public onMyRoomReady(detail:T_onMyRoomReady) {
    // console.warn('FENatverk - onMyRoomReady');
    const { room } = detail;
    this.initRoom(room);
  }

  public initRoom(myroom: Room) {
    // console.warn('FENatverk - initRoom');
    myroom.onUserLeave.on(this.onUserLeaveRoom.bind(this))
    myroom.onUserJoin.on(this.onUserJoinRoom.bind(this))
    myroom.onUserPos.on(this.onUserPos.bind(this))
    myroom.onData.on(this.onData.bind(this))
  }

  public onUserJoinRoom(detail: T_onUserJoin): void {
    // console.warn('FENatverk - onUserJoinRoom');
    const { user, room } = detail;
    // if not me joining room
    if (this.natverk.me && this.natverk.me.id !== user.id) {
      log(user.nick + " joining the room " + room.name);
      let peerPlayer = this.fe.gameState.map.newPlayerPeer(
        this.fe,
        user.nick,
        user.id
      );
      if (this.natverk.myroom) {
        if (this.natverk.myroom instanceof ChiefRoom) {
          // Test sending Player object
          // FIXME impossible to send circular object by json
          /* console.error("Players in gameState sent :") */
          /* console.error(this.fe.gameState.players) */
          this.natverk.myroom.send_to(
            user.id,
            new INatCmd(
              "GameState",
              JSON.stringify(this.fe.gameState, replacer)
            ),
            "data"
          );

          this.natverk.myroom.send_to(
            user.id,
            new INatCmd("Player", JSON.stringify(peerPlayer, replacer)),
            "data"
          );
        }
      }
      // if me joining room
    } else {
      if (this.natverk.myroom) {
        this.natverk.myroom.users.forEach((myUser) => {
          if (this.natverk.me && this.natverk.me.id !== myUser.id) {
            this.fe.gameState.map.newPlayerPeer(
              this.fe,
              myUser.nick,
              myUser.id
            );
          }
        });
      }
    }
  }

  public onUserPos(detail: T_onUserPos): void {
    // console.warn('FENatverk - onUserPos');
    const player: Moving = detail;
    const p = this.fe.gameState.players.get(player.id);
    if (p) {
      p.move(player.pos);
    } else {
      console.warn("p undefined");
    }
  }

  public onUserDisconnect(detail: T_onUserDisconnect): void {
    // console.warn('FENatverk - onUserDisconnect');
    const { user } = detail;
    log(user.nick + " has left !");
    this.fe.gameState.map.removePlayer(this.fe, user.id);
  }

  public onUserLeaveRoom(detail: T_onUserLeave): void {
    // console.warn('FENatverk - onUserLeaveRoom');
    const { user, room } = detail;
    log(user.nick + " has left the room " + room.name);
    this.fe.gameState.map.removePlayer(this.fe, user.id);
  }

  public updateGameState(gs: NatGameStateBase) {
    gs.players.forEach((p: NatPlayer) => {
      log(p);
      let our_p = this.fe.players.has(p.id)
        ? this.fe.players.get(p.id)
        : this.fe.map.newPlayerPeer(this.fe, p.nick, p.id);
      log(p);
      //Do not move yourself (it may be the case in future when controls will be
      //shared to server)
      if (our_p!.id !== this.natverk.me.id) {
        our_p!.move(p.position);
      }
    });
  }

  public onData(detail: T_onData): void {
    console.warn("FENatverk - onData");
    const data = detail;
    try {
      let msg = data as INatCmd;
      switch (msg.cmd) {
        case "Player":
          let player = JSON.parse(msg.data, reviver) as NatPlayer;
          // let player = msg.data as Player;
          log("Player:");
          log(player);
          break;
        case "FiktivLevel":
          // let level = msg.data as FiktivLevel;
          let level = JSON.parse(msg.data, reviver) as FiktivLevel;
          /* let level = msg.data; */
          log("Level:");
          log(level);
          break;
        case "GameState":
          log("Before GameState:");
          // log(msg.data);
          // let gs = (JSON.parse(msg.data)) as GameStateBase;
          let gs = JSON.parse(msg.data, reviver) as NatGameStateBase;
          // let gs = msg.data as GameStateBase;
          log("After GameState:");
          // console.warn(gs);
          this.updateGameState(gs);
          break;
      }
    } catch (err) {
      console.error("NatFiktiv ", err);
    }
  }
}
