export const trads = {
	du: {
		languages: {
			buttonDescription: 'Dutch',
		},
		languagePage: {
			header1: 'DE VERBORGEN GESCHIEDENIS',
			header2: 'VAN MIDDELBURG',
		},
		landingPageKuiperspoort: {
			header1: 'KUIPERSPOORT',
			header2: '1640',
			subtitle: 'Een zeldzaam overblijfsel uit de bloeiperiode van de Verenigde Oost-Indische Compagnie',
			content: [
				'Middelburg was in 1640 de op één na belangrijkste stad voor de VOC (Verenigde Oost-Indische Compagnie).',
				'De onderneming beschikte over talrijke gebouwen aan de Rotterdamsekaai.',
				'Ook hadden zij een grote scheepswerf waar meer dan 300 schepen werden gebouwd, en pakhuizen voor de opslag van de te verhandelen goederen lagen verspreid over de stad.',
				'Middelburg kende een enorme bedrijvigheid met een grote verscheidenheid aan ambachten en beroepen.',
				'De meeste daarvan waren verenigd in gilden.',
				'De kuipersgilde vestigde zich in 1642 boven de poort aan het einde van deze straat. Met talrijke pakhuizen en dicht bij de haven gelegen moet het een zeer drukke plaats geweest zijn.',
				' ',
				'Laten we naar de Kuiperspoort gaan in 1640 in 3, 2, 1...'
			],
		},
		landingPageAbdij: {
			header1: 'ABDIJ',
			header2: '1940',
			subtitle: 'Als een feniks herrezen uit de as',
			content: [
				'10 mei 1940: Duitsland valt Nederland binnen.',
				'Na het verschrikkelijke bombardement op Rotterdam capituleert Nederland op 15 mei 1940, met uitzondering van de provincie Zeeland.',
				'Met hulp van Franse troepen wordt geprobeerd de Duitse opmars in Zeeland te stuiten, wat resulteert in hevige gevechten bij de Sloedam.',
				'Offensief en defensief artillerievuur leiden tot het uitbreken van branden in Middelburg.',
				'De stad wordt geëvacueerd, zodat er relatief weinig slachtoffers vallen, maar de overgebleven brandweer is niet opgewassen tegen de enorme branden.',
				'De historische binnenstad gaat grotendeels in vlammen op.',
				'Een lot dat ook de historische abdij niet zou besparen.',
				'Bij de wederopbouw van de verwoeste gebouwen in de stad is er naar gestreefd de historische sfeer van Middelburg te behouden…',
				' ',
				'Zet je schrap voor de Abdij op 17 mei 1940 in 3, 2, 1...'
			],
			desc: 'Lorem ipsum...',
		},
		landingPageGrotemarkt: {
			header1: 'MARKT',
			header2: '1540',
			subtitle: 'Waar de Middeleeuwen en de Moderne Tijd elkaar ontmoeten',
			content: [
				'Zoals vandaag nog steeds gebeurt, werd hier in de Middeleeuwen markt gehouden.',
				'Toen was er echter geen plein, de markt werd gehouden naast de Westmonsterkerk en het aangrenzende kerkhof, dat in 1540 in het midden van het huidige plein stond!',
				'Het ging goed met Middelburg en in 1452 begon men met de bouw van het prachtige stadhuis.',
				'De kerk en het stadhuis hebben tot 1575 naast elkaar gestaan.',
				'De één vertegenwoordigde de Middeleeuwen, het ander de moderne tijd.',
				'Tijdens de Tachtigjarige Oorlog bleef Middelburg trouw aan de Spaanse koning Filip II.',
				'Na een belegering in 1574 gaf de stad zich over aan Willem van Oranje.',
				'De kerk verkeerde in slechte staat en werd afgebroken om plaats te maken voor de markt.',
				' ',
				'Het moet een bijzonder zicht zijn geweest, die twee gebouwen naast elkaar, laten we dus naar de Markt gaan in 1540 in 3, 2, 1...'
			],
		},
		credits: {
			content: 'Deze \'tijdreiservaring\' is het resultaat van een samenwerking tussen de gemeente Middelburg en Timescope, een pionier op het gebied van culturele innovatie ten behoeve van de opwaardering van erfgoed.'
		}
	},

	en: {
		languages: {
			buttonDescription: 'English',
		},
		languagePage: {
			header1: 'THE HIDDEN HISTORY',
			header2: 'OF MIDDELBURG',
		},
		landingPageKuiperspoort: {
			header1: 'KUIPERSPOORT',
			header2: '1640',
			subtitle: 'A rare survivor from the heydays of the Dutch East India Company',
			content: [
				'In 1640 Middelburg was the second most important town for the VOC (Dutch East India Company).',
				'They had a huge complex on the Rotterdamsekaai quayside.',
				'They also had a large shipyard where more than 300 ships were built, and warehouses for storing the goods to be traded were spread throughout the city.',
				'The town was a hive of activity with a wide variety of crafts and trades.',
				'Most of them were united in guilds.',
				"The coopers' guild established itself in 1642 above the gate at the end of this street.",
				'With its many warehouses and location close to the harbour, it must have been a busy place.',
				' ',
				'Let\'s go to the Kuiperspoort in 1640 in 3, 2, 1...'
			],
		},
		landingPageAbdij: {
			header1: 'ABDIJ',
			header2: '1940',
			subtitle: 'Like a phoenix resurrected from the ashes',
			content: [
				'May 10th, 1940: Germany invades the Netherlands.',
				'After the terrible bombing of Rotterdam, the Netherlands capitulates on May 15th, 1940 with the exception of the province of Zeeland.',
				'With the help of French troops, attempts are made to stop the German advance in Zeeland, resulting in heavy fighting at the Sloedam.',
				'Offensive and defensive artillery fire results in fires breaking out in Middelburg.',
				'The town is evacuated, so there are relatively few casualties, but the remaining fire brigade is not able to cope with the enormous fires.',
				'The historic town centre largely goes up in flames.',
				'A fate which the historic abbey would not be spared either.',
				'Throughout the reconstruction of the city’s destroyed buildings, the aim was to preserve the historic atmosphere of Middelburg, without reconstructing it.',
				' ',
				'Brace yourself for the Abbey on May 17th 1940 in 3, 2, 1...'
			],
		},
		landingPageGrotemarkt: {
			header1: 'MARKT',
			header2: '1540',
			subtitle: 'Where the Middle Ages and the Modern Era meet',
			content: [
				'Just as is done today, a market was held here in the Middle ages.',
				'There was no square though, they held it right next to the Westmonsterkerk and the adjacent cemetery, which stood in the middle of the current square in 1540!',
				'Middelburg was doing well and in 1452 they started building the beautiful town hall.',
				'The church and town hall stood side by side until 1575.',
				'One represented the Middle Ages, the other the modern era.',
				"In the Eighty Years' War, Middelburg remained loyal to the Spanish King Philip II.",
				'After a siege in 1574, the town surrendered to William of Orange.',
				'The church was in a bad state and was demolished to make room for the market.',
				' ',
				'It must have been an extraordinary sight, those two buildings side by side, so let\'s go to the Markt in 1540 in 3, 2, 1...'
			],
		},
		credits: {
			content: 'This "time travel" experience is the result of a partnership between the city of Middelburg and Timescope, a pioneer in cultural innovation for the promotion of heritage.'
		}
	},

	ge: {
		languages: {
			buttonDescription: 'German',
		},
		languagePage: {
			header1: 'DIE VERBORGENE GESCHICHTE',
			header2: 'VON MIDDELBURG',
		},
		landingPageKuiperspoort: {
			header1: 'KUIPERSPOORT',
			header2: '1640',
			subtitle: 'Ein seltenes Überbleibsel aus der Blütezeit  der niederländischen Ostindien-Kompanie',
			content: [
				'Im Jahr 1640 war Middelburg die zweitwichtigste Stadt für die niederländische Ostindien-Kompanie (VOC : Vereinigte Ostindische Kompanie).',
				'Sie besaßen einen riesigen Komplex am Rotterdamsekaai.',
				'Außerdem  verfügten sie über eine große Werft, in der über 300 Schiffe gebaut wurden wie auch über die ganze Stadt verteilte Lagerhäuser zur Aufbewahrung der Handelsgüter.',
				'In der Stadt herrschte ein reges Treiben mit einer Vielzahl von Handwerksbetrieben und Geschäften.',
				'Die meisten waren in Zünften zusammengeschlossen.',
				'Die Böttcherzunft ließ sich 1642 hinter dem Tor am Ende dieser Straße nieder.',
				'Mit seinen vielen Lagerhäusern und seiner Lage in der Nähe des Hafens muss es ein geschäftiger Ort gewesen sein.',
				' ',
				'Gehen wir zum Kuiperspoort im Jahr 1640 in 3, 2, 1...'
			],
		},
		landingPageAbdij: {
			header1: 'ABDIJ',
			header2: '1940',
			subtitle: 'Wie ein Phönix aus der Asche',
			content: [
				'10. Mai 1940: Deutschland marschiert in die Niederlande ein.',
				'Nach einem furchtbaren Bombenangriff auf Rotterdam kaptiulieren die Niederlande am 15. Mai 1940 mit Ausnahme der Provinz Zeeland.',
				'Mit Hilfe französischer Truppen wird versucht, den deutschen Vormarsch in Zeeland zu stoppen, was zu schweren Kämpfen am Sloedam führt.',
				'Offensiver und defensiver Artilleriefeuer führt zum Ausbruch von Bränden in Middelburg.',
				'Die Stadt wird evakuiert, so dass es relativ wenige Opfer gibt, aber die verbliebene Feuerwehr ist den gewaltigen Bränden nicht gewachsen.',
				'Das historische Stadtzentrum geht weitgehend in Flammen auf.',
				'Ein Schicksal, das auch der historischen Abtei nicht erspart blieb.',
				'Beim Wiederaufbau der zerstörten Gebäude der Stadt war man darum bemüht, die historische Atmosphäre Middelburgs zu erhalten, ohne sie zu rekonstruieren.',
				' ',
				'Machen Sie sich gefaßt auf die Ansicht der Abtei am 17. Mai 1940 in 1,2,3…'
			],
		},
		landingPageGrotemarkt: {
			header1: 'MARKT',
			header2: '1540',
			subtitle: 'Wo Mittelalter und  Neuzeit aufeinander stossen',
			content: [
				'So wie heute wurde hier im Mittelalter ein Markt abgehalten.',
				'Allerdings gab es damals keinen Platz, so dass die Veranstaltung direkt neben der Westmonsterkirche und dem angrenzenden Friedhof statt fand, der 1540 in der Mitte des heutigen Platzes lag!',
				'Middelburg ging es gut, und 1452 begann man mit dem Bau des schönen Rathauses.',
				'Bis 1575 standen die Kirche und das Rathaus nebeneinander.',
				'Das eine Gebäude stand für das Mittelalter, das andere für die Neuzeit.',
				'Während des achzigjährigen Krieges blieb Middelburg dem spanischen König Philip II. treu.',
				'Nach einer Belagerung im Jahr 1574 kapitulierte Middelburg vor Wilhelm von Oranien.',
				'Die Kirche war in einem schlechtem Zustand und wurde abgerissen um Platz für den Markt zu machen.',
				' ',
				'Es muss ein ausserordentlicher Anblick gewesen sein diese beiden Gebäude nebeneinander zu sehen.',
				'Gehen wir also zum Markt im Jahr 1540 in 3,2,1…'
			],
		},
		credits: {
			content: 'Dieses "Zeitreise"-Erlebnis ist das Ergebnis einer Partnerschaft zwischen der Stadt Middelburg und Timescope, einem Pionier innovativer Konzepte für die Vermittlung des kulturellen Erbes'
		}
	},
};
